import React from 'react';
import { AuthProvider } from "./AuthContext";
import TennisCourtBooking from '../src/components/TennisCourtBooking';


function App() {
    return (
        <AuthProvider>
            <TennisCourtBooking />
        </AuthProvider>
    );
}

export default App;
