// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import firebase from "./firebase";

// Create a context for authentication
const AuthContext = createContext();

// Custom hook to use the AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};

// AuthProvider component to manage authentication state and provide authentication methods
export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const getCoachData = async (email) => {
        try {
            const coachesRef = firebase.firestore().collection("coaches");
            const coachSnapshot = await coachesRef.where("email", "==", email).get();

            if (!coachSnapshot.empty) {
                const coachData = coachSnapshot.docs[0].data();
                return coachData;
            }
        } catch (error) {
            console.error("Error fetching coach data: ", error);
        }

        return null;
    };

    // Function to handle Google sign-in and registration
    const handleSignInOrRegister = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account'
        });

        firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;
                // Check if the user's email is in the approved list of coaches
                firebase
                    .firestore()
                    .collection("coaches")
                    .where("email", "==", user.email)
                    .get()
                    .then((querySnapshot) => {
                        if (!querySnapshot.empty) {
                            // User is an approved coach, set current user
                            setCurrentUser(user);
                        } else {
                            // User is not an approved coach
                            alert("Access denied. Your email is not in the approved list of coaches.");
                            firebase.auth().signOut();
                        }
                    })
                    .catch((error) => {
                        console.error("Error checking coach approval: ", error);
                    });
            })
            .catch((error) => {
                // Handle errors here
                console.error("Error during Google sign-in: ", error);
            });
    };

    const signOut = () => {
        return firebase
            .auth()
            .signOut()
            .then(() => {
                // Clear the current user state after sign-out
                setCurrentUser(null);
                // Clear any stored coach data
                localStorage.removeItem('coachData');
                // Optionally, you can also clear other stored user data or state here
            })
            .catch((error) => {
                console.error("Error during sign-out: ", error);
            });
    };

    useEffect(() => {
        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setCurrentUser(user);
            } else {
                setCurrentUser(null);
                // Clear any stored coach data when the user signs out
                localStorage.removeItem('coachData');
            }
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        handleSignInOrRegister,
        signOut,
        getCoachData
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};