import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  CircularProgress
} from '@mui/material';
import { Shimmer, Image, Breathing } from 'react-shimmer';
import { useAuth } from '../AuthContext';
import firebase from '../firebase';

function TennisCourtBooking() {
  const [isMobile, setIsMobile] = useState(false);
  const [showImages, setShowImages] = useState(true);
  const tableContainerRef = useRef(null);
  const { currentUser, handleSignInOrRegister, signOut, getCoachData } =
    useAuth();
  const [isCoachLoggedIn, setIsCoachLoggedIn] = useState(false);
  const [coachData, setCoachData] = useState(null);
  const [coaches, setCoaches] = useState([]);
  const [date, setDate] = useState(new Date());
  const [courts, setCourts] = useState([]);
  const [bookingFormOpen, setBookingFormOpen] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedCourt, setSelectedCourt] = useState(null);
  const [selectedEndSlot, setSelectedEndSlot] = useState(null);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [bookings, setBookings] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [qrCodeData, setQRCodeData] = useState(null);
  const [qPayMobileBankData, setQPayMobileBankData] = useState(null);
  const [showQRCodePage, setShowQRCodePage] = useState(false);
  const [showPaymentCompleteWarning, setShowPaymentCompleteWarning] =
    useState(false);
  const [showBookingConfirmation, setShowBookingConfirmation] = useState(false);
  const [showBankList, setShowBankList] = useState(false);

  const [bookingInfo, setBookingInfo] = useState({
    name: '',
    phoneNumber: ''
  });
  const [errors, setErrors] = useState({
    name: '',
    phoneNumber: ''
  });
  const [bookingMade, setBookingMade] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hoveredColumn, setHoveredColumn] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [bookingSubmitted, setBookingSubmitted] = useState(false);

  const glowingStyle = {
    boxShadow: '0 0 8px 2px white',
    transition: 'box-shadow 0.5s ease-in-out'
  };

  const hours = [];
  for (let hour = 6; hour <= 21; hour++) {
    hours.push(`${hour < 10 ? '0' + hour : hour}:00`);
    hours.push(`${hour < 10 ? '0' + hour : hour}:30`);
  }

  useEffect(() => {
    // Check if the screen width is less than or equal to 600px (mobile view)
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 600;
      setIsMobile(isMobileView);
      setImageHeight(isMobileView ? '70px' : '100px');
    };

    // Set initial state
    handleResize();

    // Add event listener for screen resize
    window.addEventListener('resize', handleResize);
    const interval = setInterval(() => {
      checkPayment();
    }, 5000);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      clearInterval(interval);
    };
  }, [showQRCodePage, bookingSubmitted]);

  useEffect(() => {
    const fetchCoaches = async () => {
      const snapshot = await firebase.firestore().collection('coaches').get();
      const coachesData = snapshot.docs.map(doc => {
        const coach = doc.data();
        return {
          id: doc.id,
          firstName: coach.firstName,
          lastName: coach.lastName,
          color: coach.color,
          photoURL: coach.photoURL
        };
      });

      if (coachData) {
        setCoaches([
          ...coachesData.filter(coach => coach.id !== coachData.id),
          coachesData.find(coach => coach.id === coachData.id)
        ]);
      } else {
        setCoaches(coachesData);
      }
    };

    fetchCoaches();
  }, [coachData]);

  useEffect(() => {
    const checkCoachLogin = async () => {
      if (currentUser) {
        const coachData = await getCoachData(currentUser.email);
        if (coachData) {
          setIsCoachLoggedIn(true);
          setCoachData(coachData);
          localStorage.setItem('coachData', JSON.stringify(coachData));
        } else {
          setIsCoachLoggedIn(false);
          setCoachData(null);
          localStorage.removeItem('coachData');
        }
      } else {
        setIsCoachLoggedIn(false);
        setCoachData(null);
        localStorage.removeItem('coachData');
      }
    };

    checkCoachLogin();
  }, [currentUser, getCoachData]);

  useEffect(() => {
    const storedCoachData = localStorage.getItem('coachData');
    if (storedCoachData) {
      setCoachData(JSON.parse(storedCoachData));
    }
  }, []);

  useEffect(() => {
    const fetchCourts = async () => {
      setIsLoading(true);

      const snapshot = await firebase.firestore().collection('courts').get();
      const courtData = snapshot.docs.map(doc => {
        const court = doc.data();
        const selectedDate = formatDate(date);
        const bookedSlots =
          court.bookings && court.bookings[selectedDate]
            ? Object.entries(court.bookings[selectedDate])
                .filter(([hour, booked]) => booked)
                .map(([hour]) => hour)
            : [];
        return { ...court, bookedSlots };
      });
      setCourts(courtData);

      const bookingSnapshot = await firebase
        .firestore()
        .collection('bookings')
        .doc(formatDate(date))
        .get();
      const bookingData = bookingSnapshot.exists ? bookingSnapshot.data() : {};
      setBookings(bookingData);

      setIsLoading(false);
      setBookingMade(false);
    };

    fetchCourts();
  }, [date, bookingMade]);

  const checkPayment = async () => {
    if (!bookingMade && showQRCodePage) await handleCheckPayment();
  };

  const handleCheckPayment = async () => {
    try {
      setLoading(true);
      setShowPaymentCompleteWarning(false);
      const response = await fetch(process.env.REACT_APP_QPAY_CHECK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ invoice_id: qrCodeData.invoice_id })
      });

      const data = await response.json();

      console.log(data.invoice_status, ' data');

      console.log(response.invoice_status, ' responsed');
      console.log(response, ' response');

      const { count, paid_amount, rows, invoice_status } = data.resp;

      console.log(data.resp, ' ikea');
      if (count > 0 && rows.every(row => row.payment_status === 'PAID')) {
        const db = firebase.firestore();
        const bookingDateStr = formatDate(selectedSlot);
        const bookingRef = db.collection('bookings').doc(bookingDateStr);
        const startTime = formatTime(selectedSlot);
        const endTime = formatTime(selectedEndSlot);
        const bookingKey = `${startTime}-${endTime}`;
        await bookingRef.update({
          [`${selectedCourt}.${bookingKey}.payment.status`]: 'paid',
          [`${selectedCourt}.${bookingKey}.bookingStatus`]: 'booked'
        });
        setShowQRCodePage(false);
        setShowBookingConfirmation(true);
        setBookingMade(true);
        setLoading(false);
        setBookingSubmitted(false);
      } else {
        setShowPaymentCompleteWarning(true);
        setLoading(false);
      }
    } catch (error) {
      console.error('Payment check failed:', error);
      setShowPaymentCompleteWarning(true);
      setLoading(false);
    }
  };

  const BankListOverlay = ({ bankData, onClose }) => {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            maxWidth: '90%',
            maxHeight: '80vh',
            overflowY: 'auto'
          }}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center'
            }}>
            {bankData.map((bank, index) => (
              <div
                key={index}
                style={{
                  padding: '10px',
                  textAlign: 'center',
                  width: '33.33%',
                  boxSizing: 'border-box'
                }}>
                <a href={bank.link} target='_blank' rel='noopener noreferrer'>
                  <img
                    src={bank.logo}
                    alt={bank.name}
                    style={{
                      width: '100%',
                      height: 'auto',
                      borderRadius: '15px'
                    }}
                  />
                </a>
              </div>
            ))}
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Button
              onClick={onClose}
              variant='contained'
              style={{ backgroundColor: '#3554A4' }}>
              Close
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const handleCloseQRCodePage = () => {
    if (
      window.confirm('Та энэ захиалгыг цуцлах гэж байгаадаа итгэлтэй байна уу?')
    ) {
      const bookingDateStr = formatDate(selectedSlot);
      const startTime = formatTime(selectedSlot);
      const endTime = formatTime(selectedEndSlot);
      const bookingKey = `${startTime}-${endTime}`;

      const db = firebase.firestore();
      const bookingRef = db.collection('bookings').doc(bookingDateStr);
      const courtRef = db.collection('courts').doc(selectedCourt);

      db.runTransaction(transaction => {
        return transaction.get(bookingRef).then(bookingDoc => {
          if (bookingDoc.exists) {
            const bookingData = bookingDoc.data();
            const courtBooking = bookingData[selectedCourt];

            if (
              courtBooking &&
              courtBooking[bookingKey] &&
              courtBooking[bookingKey].bookingStatus === 'pending'
            ) {
              // Read the court document
              return transaction.get(courtRef).then(courtDoc => {
                if (courtDoc.exists) {
                  const courtData = courtDoc.data();
                  const bookings = courtData.bookings || {};
                  const dailyBookings = bookings[bookingDateStr] || {};

                  const updatedDailyBookings = { ...dailyBookings };

                  // Iterate over the time slots between start and end time
                  let currentTime = new Date(selectedSlot);
                  while (currentTime < selectedEndSlot) {
                    const timeKey = formatTime(currentTime);
                    delete updatedDailyBookings[timeKey];
                    currentTime.setMinutes(currentTime.getMinutes() + 30);
                  }

                  const updatedBookings = {
                    ...bookings,
                    [bookingDateStr]: updatedDailyBookings
                  };

                  // Delete the temporary booking data from the bookings collection
                  const updatedCourtBooking = { ...courtBooking };
                  delete updatedCourtBooking[bookingKey];

                  // Perform the writes
                  transaction.update(bookingRef, {
                    [selectedCourt]: updatedCourtBooking
                  });
                  transaction.update(courtRef, { bookings: updatedBookings });
                }
              });
            }
          }
        });
      })
        .then(() => {
          console.log(
            'Temporary booking data and hourly slot fields deleted successfully.'
          );
          setShowQRCodePage(false);
          setQRCodeData(null);
          handleCloseBookingForm();
        })
        .catch(error => {
          console.error(
            'Error deleting temporary booking data and hourly slot fields:',
            error
          );
        });
    }
  };

  const BookingConfirmation = () => {
    const bookingDuration = Math.abs(selectedEndSlot - selectedSlot) / 36e5;

    const handleCloseConfirmation = () => {
      setShowBookingConfirmation(false);
      handleCloseBookingForm();
    };

    return (
      <div>
        <DialogTitle
          style={{
            backgroundColor: '#3554A4',
            color: 'white',
            fontWeight: 'bold',
            marginBottom: '10px'
          }}>
          Захиалга Баталгаажуулалт
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px'
            }}>
            <img
              src={selectedCourtObject?.pictureUrl}
              alt='Tennis Court'
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                marginRight: '16px'
              }}
            />
            <Typography variant='h6'>{selectedCourtObject?.name}</Typography>
          </div>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Өдөр</TableCell>
                <TableCell>
                  {selectedSlot.toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                  })}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Цаг</TableCell>
                <TableCell>
                  {formatTime(selectedSlot)} - {formatTime(selectedEndSlot)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Үргэлжлэх хугацаа</TableCell>
                <TableCell>{bookingDuration} цаг</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Нэр</TableCell>
                <TableCell>
                  {isCoachLoggedIn
                    ? `${coachData.firstName} ${coachData.lastName}`
                    : bookingInfo.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Холбогдох утас</TableCell>
                <TableCell>
                  {isCoachLoggedIn ? coachData.phone : bookingInfo.phoneNumber}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>⚠️ Анхаарах зүйл</TableCell>
                <TableCell>
                  Захиалга хийгдсэн тохиолдолд, цуцлах боломжгүй гэдгийг
                  анхаарна уу. Тиймээс буцаан олголт хийгдэхгүй болно.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseConfirmation}
            variant='contained'
            style={{ backgroundColor: '#3554A4' }}>
            Хаах
          </Button>
        </DialogActions>
      </div>
    );
  };

  const fetchData = async () => {
    setIsLoading(true);

    const snapshot = await firebase.firestore().collection('courts').get();
    const courtData = snapshot.docs.map(doc => {
      const court = doc.data();
      const selectedDate = formatDate(date);
      const bookedSlots =
        court.bookings && court.bookings[selectedDate]
          ? Object.entries(court.bookings[selectedDate])
              .filter(([hour, booked]) => booked)
              .map(([hour]) => hour)
          : [];
      return { ...court, bookedSlots };
    });
    setCourts(courtData);

    const bookingSnapshot = await firebase
      .firestore()
      .collection('bookings')
      .doc(formatDate(date))
      .get();
    const bookingData = bookingSnapshot.exists ? bookingSnapshot.data() : {};
    setBookings(bookingData);

    setIsLoading(false);
    setBookingMade(false);
  };

  const [imageHeight, setImageHeight] = useState('auto');
  const [profileHeight, setProfileHeight] = useState('auto');

  const handleTableScroll = useCallback(
    event => {
      const tableContainer = event.currentTarget;
      const scrollPosition = tableContainer.scrollTop;

      if (scrollPosition > 0) {
        setShowImages(false);
        setImageHeight('0px');
        setProfileHeight('0px');
      } else {
        setShowImages(true);
        setImageHeight(isMobile ? '70px' : '100px');
        setProfileHeight('auto');
      }
    },
    [isMobile]
  );

  const getBookingDetails = (courtId, slot) => {
    if (!bookings[courtId]) {
      return null;
    }

    for (const [key, value] of Object.entries(bookings[courtId])) {
      const [startTime, endTime] = key.split('-');
      const startSlot = new Date(date);
      const [startHour, startMinute] = startTime.split(':').map(Number);
      startSlot.setHours(startHour, startMinute, 0, 0);

      const endSlot = new Date(date);
      const [endHour, endMinute] = endTime.split(':').map(Number);
      endSlot.setHours(endHour, endMinute, 0, 0);

      if (slot >= startSlot && slot < endSlot) {
        return value;
      }
    }

    return null;
  };

  const handleBooking = (courtId, slot) => {
    const [selectedHour, selectedMinute] = slot.split(':').map(Number);
    const selectedDateTime = new Date(date);
    selectedDateTime.setHours(selectedHour, selectedMinute, 0, 0);
    setSelectedSlot(selectedDateTime);
    setSelectedCourt(courtId);
    setBookingFormOpen(true);
    setBookingInfo({
      name: '',
      phoneNumber: ''
    });
    if (isCoachLoggedIn) {
      setSelectedCoach(coachData.id);
    } else {
      setSelectedCoach(null);
    }
  };

  const selectedCourtObject = courts.find(court => court.id === selectedCourt);

  const isSlotBooked = (courtId, slot) => {
    const court = courts.find(court => court.id === courtId);
    return court && court.bookedSlots && court.bookedSlots.includes(slot);
  };

  const formatDate = date => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const handlePrevDay = () => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1);
    setDate(newDate);
  };

  const handleNextDay = () => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    setDate(newDate);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setBookingInfo({ ...bookingInfo, [name]: value });
  };

  const handleCloseBookingForm = (even, reason) => {
    if (reason && reason === 'backdropClick') return;

    setBookingFormOpen(false);
    setSelectedSlot(null);
    setSelectedCourt(null);
    setSelectedEndSlot(null);
    setSelectedCoach(null);
    setErrors({
      name: '',
      phoneNumber: '',
      endSlot: ''
    });
    setBookings({}); // Reset the bookings state variable
    setLoading(false);

    if (isCoachLoggedIn) {
      setBookingMade(true);
    }
    setShowQRCodePage(null);
    setShowPaymentCompleteWarning(false);

    fetchData(); // Call the fetchData function to reload the data and show the shimmer effect
  };

  const validateBookingInfo = (bookingInfo, isCoachLoggedIn) => {
    const errors = {};
    if (!isCoachLoggedIn) {
      if (!bookingInfo.name.trim()) {
        errors.name = 'Хоосон байна';
      }
      if (!bookingInfo.phoneNumber.trim()) {
        errors.phoneNumber = 'Хоосон байна';
      }
    }
    if (!selectedEndSlot) {
      errors.endSlot = 'Хоосон байна';
    }
    return errors;
  };

  const updateCourtBookings = async (
    db,
    courtRef,
    bookingDateStr,
    selectedSlot,
    selectedEndSlot
  ) => {
    await db.runTransaction(async transaction => {
      const courtDoc = await transaction.get(courtRef);
      if (!courtDoc.exists) {
        throw 'Document does not exist!';
      }
      const courtData = courtDoc.data();
      const bookings = courtData.bookings || {};
      const dailyBookings = bookings[bookingDateStr] || {};

      let currentTime = selectedSlot.getTime();
      while (currentTime < selectedEndSlot.getTime()) {
        const hour = new Date(currentTime).getHours();
        const minute = new Date(currentTime).getMinutes();
        const timeKey = formatTimeKey(hour, minute);
        dailyBookings[timeKey] = true;
        currentTime += 1800000;
      }

      bookings[bookingDateStr] = dailyBookings;
      transaction.update(courtRef, { bookings: bookings });
    });
  };

  const updateBookingData = async (
    db,
    bookingRef,
    bookingDateStr,
    selectedCourt,
    bookingKey,
    bookingData
  ) => {
    await db.runTransaction(async transaction => {
      const bookingDoc = await transaction.get(bookingRef);
      let updatedBookingData = {
        [selectedCourt]: {
          [bookingKey]: {
            ...bookingData,
            payment: {
              ...bookingData.payment
            }
          }
        }
      };

      if (bookingDoc.exists) {
        const existingData = bookingDoc.data();
        if (existingData[selectedCourt]) {
          existingData[selectedCourt][bookingKey] = {
            ...bookingData,
            payment: {
              ...bookingData.payment
            }
          };
          updatedBookingData = existingData;
        }
      }

      transaction.set(bookingRef, updatedBookingData, { merge: true });
    });
  };

  const handleBookingSubmit = async () => {
    const db = firebase.firestore();

    const bookingDateStr = formatDate(selectedSlot);
    const startTime = formatTime(selectedSlot);
    const endTime = formatTime(selectedEndSlot);
    const bookingKey = `${startTime}-${endTime}`;
    const fee = await calculateTotalFee(
      selectedSlot,
      selectedEndSlot,
      selectedCourtObject,
      false
    );

    const errors = validateBookingInfo(bookingInfo, isCoachLoggedIn);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {
      console.log('booking submitted true');
      setLoading(true);
      setBookingSubmitted(true);

      try {
        const courtRef = db.collection('courts').doc(selectedCourt);
        await updateCourtBookings(
          db,
          courtRef,
          bookingDateStr,
          selectedSlot,
          selectedEndSlot
        );
        const bookingRef = db.collection('bookings').doc(bookingDateStr);

        let bookingData;
        if (selectedCoach) {
          const coach = coaches.find(c => c.id === selectedCoach);
          bookingData = {
            isCoach: true,
            name: `${coach.firstName} ${coach.lastName}`,
            color: coach.color,
            startTime: `${bookingDateStr} ${startTime}`,
            endTime: `${bookingDateStr} ${endTime}`,
            bookingStatus: 'booked'
          };
        } else if (isCoachLoggedIn) {
          bookingData = {
            isCoach: true,
            name: `${coachData.firstName} ${coachData.lastName} (та өөрөө)`,
            color: coachData.color,
            phone: coachData.phone,
            startTime: `${bookingDateStr} ${startTime}`,
            endTime: `${bookingDateStr} ${endTime}`,
            bookingStatus: 'booked'
          };
        } else {
          bookingData = {
            name: bookingInfo.name,
            phone: `+976${bookingInfo.phoneNumber}`,
            startTime: `${bookingDateStr} ${startTime}`,
            endTime: `${bookingDateStr} ${endTime}`,
            payment: {
              status: 'pending'
            },
            bookingStatus: 'pending'
          };
        }

        await updateBookingData(
          db,
          bookingRef,
          bookingDateStr,
          selectedCourt,
          bookingKey,
          bookingData
        );

        if (bookingData.bookingStatus === 'booked' && isCoachLoggedIn) {
          setBookingMade(true);
          setLoading(false);
          handleCloseBookingForm();
        } else {
          const response = await fetch(process.env.REACT_APP_QPAY_INVOICE_URL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              amount: fee,
              court: selectedCourt
            })
          });

          const data = await response.json();
          const { qr_image, invoice_id, qr_text, urls } = data.response;

          setQPayMobileBankData(urls);
          // Update the booking data with the payment information
          await updateBookingData(
            db,
            bookingRef,
            bookingDateStr,
            selectedCourt,
            bookingKey,
            {
              ...bookingData,
              payment: {
                ...bookingData.payment,
                invoice_id,
                qr_text,
                qr_image,
                urls
              }
            }
          );

          setQRCodeData({ qr_image, invoice_id });
          setShowQRCodePage(true);
        }
      } catch (error) {
        console.error('Booking failed:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const generateAvailableEndSlots = () => {
    const availableSlots = [];
    let currentTime = selectedSlot.getTime();

    let nextBookingTime = getNextBookingTime(selectedCourt, selectedSlot);

    let maxDurationTime = nextBookingTime
      ? nextBookingTime
      : new Date(formatDate(date) + 'T22:00:00').getTime();

    currentTime += 30 * 60 * 1000;
    while (currentTime <= maxDurationTime) {
      const currentSlot = new Date(currentTime);
      if (!isSlotBooked(selectedCourt, formatTime(currentSlot))) {
        availableSlots.push(currentSlot);
      } else {
        // If the current slot is booked, include it as an available end slot
        availableSlots.push(currentSlot);
        break;
      }
      currentTime += 30 * 60 * 1000;
    }

    return availableSlots;
  };

  const formatTime = date => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${hours < 10 ? '0' + hours : hours}:${
      minutes < 10 ? '0' + minutes : minutes
    }`;
  };

  const formatTimeKey = (hour, minute) =>
    `${hour < 10 ? '0' + hour : hour}:${minute < 30 ? '00' : '30'}`;

  const getNextBookingTime = (courtId, startTime) => {
    const selectedDate = formatDate(startTime);
    const court = courts.find(court => court.id === courtId);
    if (court && court.bookings && court.bookings[selectedDate]) {
      const bookedHours = Object.keys(court.bookings[selectedDate]);
      for (let hour of bookedHours) {
        const [bookedHour, bookedMinute] = hour.split(':').map(Number);
        const bookingStartTime = new Date(startTime);
        bookingStartTime.setHours(bookedHour, bookedMinute, 0, 0);
        if (bookingStartTime.getTime() > startTime.getTime()) {
          return bookingStartTime.getTime();
        }
      }
    }
    return null;
  };

  const checkDiscountTimeIncluded = (
    selectedCourtObject,
    startTime,
    endTime
  ) => {};

  const calculateTotalFee = (
    startSlot,
    endSlot,
    selectedCourtObject,
    kNotation
  ) => {
    const hourlyFee = selectedCourtObject?.fee;

    // const { discountEndTime, discountPercentage, discountStartTime } =
    //   selectedCourtObject;

    // const discountCheck = checkDiscountTimeIncluded(
    //   selectedCourtObject,
    //   startSlot,
    //   endSlot
    // );

    if (!hourlyFee) return '';

    const durationInMillis = endSlot?.getTime() - startSlot?.getTime() || 0;
    const durationInHours = durationInMillis / 3600000; // Convert milliseconds to hours

    let fee = 0;
    if (hourlyFee.includes('K')) {
      fee = parseInt(hourlyFee.replace('K', ''), 10) * 1000; // Convert 'K' notation to actual fee
    } else {
      fee = parseInt(hourlyFee, 10);
    }

    const totalFee = Math.ceil(durationInHours * fee);

    if (kNotation) {
      return `${Math.floor(totalFee / 1000)}K`;
    } else {
      return totalFee;
    }
  };

  const ShimmerTable = () => (
    <TableContainer
      component={Paper}
      style={{ maxHeight: '800px', overflowY: 'hidden' }}>
      <Shimmer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ padding: '16px', width: '80px' }}>
                <Breathing style={{ width: '40px', height: '16px' }} />
              </TableCell>
              {Array.from({ length: 6 }).map((_, index) => (
                <TableCell key={index} style={{ padding: '16px' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}>
                    <Image
                      style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '50%'
                      }}
                    />
                    <div style={{ marginLeft: '8px' }}>
                      <Breathing
                        style={{
                          width: '100px',
                          height: '16px',
                          marginBottom: '8px'
                        }}
                      />
                      <Breathing style={{ width: '80px', height: '12px' }} />
                    </div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.from({ length: 32 }).map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell style={{ padding: '16px', width: '80px' }}>
                  <Breathing style={{ width: '40px', height: '16px' }} />
                </TableCell>
                {Array.from({ length: 6 }).map((_, columnIndex) => (
                  <TableCell key={columnIndex} style={{ padding: '16px' }}>
                    <Breathing style={{ width: '100%', height: '64px' }} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Shimmer>
    </TableContainer>
  );

  return (
    <div>
      <div
        style={{
          textAlign: 'center',
          height: profileHeight,
          overflow: 'hidden',
          transition: 'height 0.3s ease-in-out'
        }}>
        {currentUser ? (
          <div>
            {coachData ? (
              <>
                <img
                  src={coachData.photoURL}
                  alt='Coach Profile'
                  style={{
                    width: '64px',
                    height: '64px',
                    borderRadius: '50%',
                    marginRight: '16px'
                  }}
                />
                <Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>
                  {coachData.firstName}. {coachData.lastName[0]}
                </Typography>
              </>
            ) : (
              <Typography variant='subtitle1' style={{ fontWeight: 'bold' }}>
                Loading coach data...
              </Typography>
            )}
            <Button
              onClick={signOut}
              style={{ backgroundColor: '#3554A4', color: 'white' }}>
              ГАРАХ
            </Button>
          </div>
        ) : (
          <img
            src='/tnsd-logo.png'
            alt='TNSD Logo'
            style={{
              width: '64px',
              height: 'auto',
              marginRight: '16px',
              cursor: 'pointer'
            }}
            onClick={handleSignInOrRegister}
          />
        )}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px'
        }}>
        <Button
          variant='contained'
          style={{ backgroundColor: '#3554A4' }}
          onClick={handlePrevDay}>
          ӨМНӨХ
        </Button>
        <Typography style={{ size: '18px', fontWeight: 'bold' }}>
          {date.toDateString()}
        </Typography>
        <Button
          variant='contained'
          style={{ backgroundColor: '#3554A4' }}
          onClick={handleNextDay}>
          ДАРАА
        </Button>
      </div>

      {isLoading ? (
        <ShimmerTable />
      ) : (
        <TableContainer
          component={Paper}
          style={{ maxHeight: '800px' }}
          ref={tableContainerRef}
          onScroll={isMobile ? handleTableScroll : null}
          onWheel={event => {
            const { deltaX, deltaY } = event;
            const {
              scrollTop,
              scrollLeft,
              scrollWidth,
              clientWidth,
              scrollHeight,
              clientHeight
            } = event.currentTarget;

            if (Math.abs(deltaX) > Math.abs(deltaY)) {
              // Horizontal scrolling
              event.currentTarget.scrollLeft += deltaX;
              event.preventDefault();
            } else {
              // Vertical scrolling
              if (
                (scrollTop === 0 && deltaY < 0) ||
                (scrollTop + clientHeight === scrollHeight && deltaY > 0)
              ) {
                event.preventDefault();
              }
            }
          }}
          onWheelCapture={event => {
            event.stopPropagation();
          }}>
          <Table>
            <TableHead style={{ position: 'sticky', top: 0 }}>
              <TableRow>
                <TableCell
                  style={{
                    color: 'black',
                    paddingLeft: '5px',
                    fontWeight: 'bold',
                    textAlign: 'left',
                    backgroundColor: 'white',
                    position: 'sticky',
                    left: 0,
                    zIndex: 1
                  }}>
                  Цаг \ Талбай
                </TableCell>
                {courts.map((court, index) => {
                  if (court.hidden) {
                    return <></>;
                  }
                  return (
                    <TableCell
                      key={court.id}
                      onMouseEnter={() => {
                        setHoveredRow(null);
                        setHoveredColumn(index + 1);
                      }}
                      onMouseLeave={() => setHoveredColumn(null)}
                      style={{
                        color: hoveredColumn === index + 1 ? 'white' : 'black',
                        backgroundColor:
                          hoveredColumn === index + 1 ? '#3554A4' : '#fff',
                        padding: '16px',
                        textAlign: 'center',
                        boxShadow:
                          hoveredColumn === index + 1 ? glowingStyle : 'none'
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}>
                        <div
                          style={{
                            width: isMobile ? '70px' : '100px',
                            height: imageHeight,
                            borderRadius: '50%',
                            overflow: 'hidden',
                            transition: 'height 0.3s ease-in-out'
                          }}>
                          <img
                            src={court.pictureUrl}
                            alt='Tennis Court Images'
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              opacity: showImages ? 1 : 0,
                              transition: 'opacity 0.3s ease-in-out'
                            }}
                          />
                        </div>
                        <div
                          style={{
                            fontWeight: 'bold',
                            marginTop: showImages ? '8px' : '0',
                            fontSize: isMobile ? '14px' : '18px',
                            transition: 'margin-top 0.3s ease-in-out'
                          }}>
                          {court.name}
                        </div>
                        <div
                          style={{
                            fontWeight: 'bold',
                            fontSize: isMobile ? '10px' : '14px',
                            transition: 'margin-top 0.3s ease-in-out'
                          }}>
                          {court.fee} / цаг
                        </div>
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {hours.map((hour, rowIndex) => (
                <TableRow key={hour}>
                  <TableCell
                    style={{
                      backgroundColor:
                        hoveredRow === rowIndex ? '#3554A4' : 'white',
                      color: hoveredRow === rowIndex ? 'white' : 'black',
                      fontWeight: hoveredRow === rowIndex ? 'bold' : 'normal',
                      width: '80px',
                      position: 'sticky',
                      left: 0,
                      zIndex: 1
                    }}
                    onMouseEnter={() => {
                      setHoveredRow(rowIndex);
                      setHoveredColumn(0);
                    }}
                    onMouseLeave={() => {
                      // Check if the mouse is not hovering over any column cell
                      if (hoveredColumn === 0) {
                        setHoveredRow(null);
                        setHoveredColumn(null);
                      }
                    }}>
                    {hour}
                  </TableCell>
                  {courts.map((court, columnIndex) => {
                    if (court.hidden) {
                      return <></>;
                    }
                    const slot = new Date(date);
                    const [slotHour, slotMinute] = hour.split(':').map(Number);
                    slot.setHours(slotHour, slotMinute, 0, 0);

                    const bookingDetails = getBookingDetails(court.id, slot);
                    const isBooked =
                      bookingDetails !== null &&
                      bookingDetails['bookingStatus'] === 'booked';

                    const currentTime = new Date();
                    const isPastTime = slot < currentTime;

                    if (isBooked) {
                      const nextSlot = new Date(slot);
                      nextSlot.setMinutes(slot.getMinutes() + 30);
                      let nextBookingDetails = getBookingDetails(
                        court.id,
                        nextSlot
                      );

                      let rowSpan = 1;
                      while (
                        nextBookingDetails &&
                        nextBookingDetails.name === bookingDetails.name &&
                        nextBookingDetails.phone === bookingDetails.phone
                      ) {
                        rowSpan++;
                        nextSlot.setMinutes(nextSlot.getMinutes() + 30);
                        nextBookingDetails = getBookingDetails(
                          court.id,
                          nextSlot
                        );
                      }

                      if (
                        rowIndex === 0 ||
                        !getBookingDetails(
                          court.id,
                          new Date(slot.getTime() - 1800000)
                        ) ||
                        (getBookingDetails(
                          court.id,
                          new Date(slot.getTime() - 1800000)
                        ) &&
                          getBookingDetails(
                            court.id,
                            new Date(slot.getTime() - 1800000)
                          ).name !== bookingDetails.name)
                      ) {
                        const isCoachBooking = bookingDetails.isCoach;
                        const bookingColor = isCoachBooking
                          ? bookingDetails.color
                          : '#ccc';
                        const textColor = isCoachBooking ? 'white' : 'black';

                        return (
                          <TableCell
                            key={`${court.id}-${hour}`}
                            rowSpan={rowSpan}
                            onMouseEnter={() => {
                              setHoveredRow(rowIndex);
                              setHoveredColumn(columnIndex + 1);
                            }}
                            onMouseLeave={() => {
                              setHoveredRow(null);
                              setHoveredColumn(null);
                            }}
                            style={{
                              background: bookingColor,
                              color: textColor,
                              cursor: 'default',
                              boxShadow:
                                hoveredColumn === columnIndex + 1
                                  ? glowingStyle
                                  : 'none',
                              textAlign: 'center'
                            }}>
                            <div>
                              {isCoachBooking ? `Хичээлтэй` : `Захиалгатай`}
                            </div>
                            <div>
                              {isCoachBooking
                                ? bookingDetails.name
                                : bookingDetails.name.slice(0, 10)}
                            </div>
                            <div style={{ fontSize: '12px' }}>
                              {formatTime(new Date(bookingDetails.startTime))} -{' '}
                              {formatTime(new Date(bookingDetails.endTime))}
                            </div>
                          </TableCell>
                        );
                      } else {
                        return null;
                      }
                    } else {
                      return (
                        <TableCell
                          key={`${court.id}-${hour}`}
                          onMouseEnter={() => {
                            setHoveredRow(rowIndex);
                            setHoveredColumn(columnIndex + 1);
                          }}
                          onMouseLeave={() => {
                            setHoveredRow(null);
                            setHoveredColumn(null);
                          }}
                          style={{
                            background: isPastTime
                              ? '#ddd'
                              : hoveredColumn === columnIndex + 1
                              ? 'white'
                              : '#eee',
                            cursor: isPastTime ? 'not-allowed' : 'pointer',
                            boxShadow:
                              hoveredColumn === columnIndex + 1
                                ? glowingStyle
                                : 'none',
                            textAlign: 'center',
                            color: isPastTime ? '#999' : 'inherit'
                          }}
                          onClick={() => {
                            if (!isPastTime) {
                              handleBooking(court.id, hour);
                            }
                          }}>
                          {isPastTime ? '' : '+'}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog
        open={bookingFormOpen || showQRCodePage || showBookingConfirmation}
        onClose={handleCloseBookingForm}>
        {showBookingConfirmation ? (
          <BookingConfirmation />
        ) : showQRCodePage ? (
          <div>
            <DialogTitle
              style={{
                backgroundColor: '#3554A4',
                color: 'white',
                fontWeight: 'bold',
                marginBottom: '10px'
              }}>
              QPay Төлбөр
            </DialogTitle>
            <DialogContent>
              <h5>Доорх QR кодийг QPay ээр уншуулж, төлбөрөө төлнө үү.</h5>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img
                  src={`data:image/jpeg;base64,${qrCodeData.qr_image}`}
                  alt='QR Code'
                  style={{ maxWidth: '100%', height: 'auto' }}
                />
              </div>
              {showPaymentCompleteWarning && (
                <div>Төлбөр хараахан төлөгдөөгүй байна.</div>
              )}
              {isMobile && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Button onClick={() => setShowBankList(true)}>
                    Банкны апп-аар төлөх
                  </Button>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCheckPayment}
                variant='contained'
                style={{ backgroundColor: '#3554A4' }}>
                {loading ? <CircularProgress size={24} /> : 'Төлбөр Шалгах'}
              </Button>
              <Button
                onClick={handleCloseQRCodePage}
                variant='contained'
                style={{ backgroundColor: '#3554A4' }}>
                Цуцлах
              </Button>
            </DialogActions>
            {isMobile && showBankList && (
              <BankListOverlay
                bankData={qPayMobileBankData}
                onClose={() => setShowBankList(false)}
              />
            )}
          </div>
        ) : (
          <>
            <DialogTitle
              style={{
                backgroundColor: '#3554A4',
                color: 'white',
                fontWeight: 'bold',
                marginBottom: '10px'
              }}>
              Захиалга
            </DialogTitle>
            <DialogContent>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '10px'
                }}>
                <img
                  src={selectedCourtObject?.pictureUrl}
                  alt='Tennis Court'
                  style={{
                    width: '100px',
                    height: '100px',
                    borderRadius: '50%',
                    marginRight: '16px'
                  }}
                />
                <Typography variant='h6'>
                  {selectedCourtObject?.name}
                </Typography>
              </div>
              {isCoachLoggedIn ? (
                <FormControl
                  fullWidth
                  variant='outlined'
                  style={{ marginTop: '16px' }}>
                  <InputLabel htmlFor='coach-select'>Хичээлийн багш</InputLabel>
                  <Select
                    labelId='coach-select-label'
                    id='coach-select'
                    value={selectedCoach || ''}
                    onChange={e => setSelectedCoach(e.target.value)}
                    displayEmpty
                    input={<OutlinedInput labelWidth={120} notchedOutline />}
                    renderValue={selected => (
                      <div>
                        {selected
                          ? `${
                              coaches.find(coach => coach.id === selected)
                                .firstName
                            } ${
                              coaches.find(coach => coach.id === selected)
                                .lastName
                            }`
                          : 'Хичээлийн багшийг сонгоно уу'}
                      </div>
                    )}>
                    {coachData && (
                      <MenuItem
                        value={
                          coachData.id
                        }>{`${coachData.firstName} ${coachData.lastName} (та өөрөө)`}</MenuItem>
                    )}
                    {coaches
                      .filter(coach => coachData && coach.id !== coachData.id)
                      .sort((a, b) => a.firstName.localeCompare(b.firstName))
                      .map(coach => (
                        <MenuItem key={coach.id} value={coach.id}>
                          {`${coach.firstName} ${coach.lastName}`}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : (
                <>
                  <TextField
                    margin='dense'
                    id='name'
                    label='Нэр'
                    type='text'
                    name='name'
                    value={bookingInfo.name}
                    onChange={handleInputChange}
                    fullWidth
                    required
                    error={!!errors.name}
                    helperText={errors.name}
                  />
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      margin='dense'
                      id='phoneNumber'
                      label='Утас'
                      type='tel'
                      name='phoneNumber'
                      value={bookingInfo.phoneNumber}
                      onChange={e => {
                        const newValue = e.target.value.startsWith('+976')
                          ? e.target.value.slice(4)
                          : e.target.value;
                        handleInputChange({
                          target: { name: 'phoneNumber', value: newValue }
                        });
                      }}
                      fullWidth
                      required
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber}
                      InputProps={{
                        startAdornment: (
                          <span style={{ marginRight: '8px' }}>+976</span>
                        )
                      }}
                    />
                  </div>
                </>
              )}
              <TextField
                margin='dense'
                id='startDate'
                label='Эхлэх цаг'
                type='text'
                value={
                  selectedSlot
                    ? selectedSlot.toLocaleString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false
                      }) +
                      (selectedSlot
                        ? selectedSlot.getHours() >= 12
                          ? ' PM'
                          : ' AM'
                        : '')
                    : ''
                }
                fullWidth
                disabled
              />
              <Select
                value={selectedEndSlot || ''}
                onChange={e => setSelectedEndSlot(e.target.value)}
                fullWidth
                displayEmpty
                renderValue={selected => (
                  <div>
                    {selected
                      ? `${formatTime(selected)} хүртэл`
                      : 'Дуусах хугацаа'}
                  </div>
                )}
                required
                error={!!errors.endSlot}>
                {selectedSlot &&
                  generateAvailableEndSlots().map((slot, index) => (
                    <MenuItem key={index} value={slot}>
                      {formatTime(slot)}
                    </MenuItem>
                  ))}
              </Select>
              {!isCoachLoggedIn && selectedEndSlot && (
                <Typography
                  variant='body1'
                  style={{
                    marginTop: '20px',
                    color: '#3554A4',
                    fontWeight: 'bold'
                  }}>
                  Төлбөр:{' '}
                  {calculateTotalFee(
                    selectedSlot,
                    selectedEndSlot,
                    selectedCourtObject,
                    true
                  )}
                </Typography>
              )}
              {!isCoachLoggedIn && (
                <Typography>
                  <p>⚠️ Анхаарах зүйл ⚠️ </p>
                  Захиалга хийгдсэн тохиолдолд, цуцлах боломжгүй гэдгийг
                  анхаарна уу. Тиймээс буцаан олголт хийгдэхгүй болно.
                </Typography>
              )}
              {errors.endSlot && (
                <Typography variant='caption' color='error'>
                  {errors.endSlot}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseBookingForm}>Болих</Button>
              <Button
                onClick={handleBookingSubmit}
                variant='contained'
                style={{ backgroundColor: '#3554A4' }}>
                {loading ? <CircularProgress size={24} /> : 'Захиалах'}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}

export default TennisCourtBooking;
